@import "../../../common-var.less";
@keyframes loading{
    0% { transform: rotate(60deg) }
    20% { transform: rotate(120deg)}
    40% {transform: rotate(180deg)}
    60% {transform: rotate(240deg)}
    80% {transform: rotate(300deg)}
    100% {transform: rotate(360deg)}
}
.bg-gray{
    background-color: #fafafa;
}
.logo-box{
    width: 2.5rem;
    height: .36rem;
    display: inline-block;
}
.h-container{
    height: .9rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &--selected{
        flex-direction: column;
        justify-content: flex-start;
        .h-menu__top{
            height: .9rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-shadow: 0px 6px 24px 0px rgba(17, 17, 18, 0.1);
            margin-bottom: .6rem;
        }
    }
}
.switch-button {
    width: 20px;
    height: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .hamburger{
        position: relative;
        display: block;
        height: 2px;
        background-color: #ffffff;
        -webkit-transition: background-color .3s;
        transition: background-color .3s;
        &:before,&:after{
            content: "";
            display: block;
            position: absolute;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: #ffffff;
            transform: translateY(0) rotate(0);
            transition: background-color .3s,transform .3s;
        }
        &:before {
            top: -7px;
        }
        &:after{
            bottom: -7px;
        }
    }
    // &.b-theme{

    // }
    &.open{
        .hamburger{
            background-color: transparent;
            &:before{
                transform: translateY(7px) rotate(45deg);
            }
            &:after{
                transform: translateY(-7px) rotate(-45deg);
            }
        }
    }
}
.h-menu{
    background-color: #fff;
    position: fixed;
    transition: all .6s;
    height: 0;
    left: 0;
    top: .9rem;
    bottom: 0;
    right: 0;
    width: 100vw;
    overflow: hidden;
    z-index: 9999;
    .h-menu__list{
        padding-top: .56rem;
        .h-menu__item{
            position: relative;
            .b-1px-b;
            > a{
                color: @black;
                width: 100%;
                box-sizing: border-box;
                font-size: .3rem;
                font-weight: bold;
                padding: 0.3rem 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            .h-menu__dropdown{
                padding-bottom: .4rem;
                display: none;
                // overflow: hidden;
                // height: 0;
                transition: all .3s;
                .h-menu__dropdown-item{
                    > a{
                        width: 100%;
                        box-sizing: border-box;
                        display: inline-block;
                        padding: .14rem .15rem;
                        font-size: .28rem;
                        font-weight: normal;
                        color: @black;
                    }
                    &.active{
                        > a{
                            color: @blue;
                        }
                    }
                }
            }
            &.h-menu__sub-item{
                .icon-box{
                    transition: all .3s;
                }
            }
            &.active{
                > a{
                    color: @blue;
                }
                .h-menu__dropdown{
                    display: block;
                    // padding: 0;
                }
                .icon-box{
                    transform: rotate(180deg);
                }
            }
        }
    }
    &.h-menu--open{
        height: 100%;
    }
}

footer{
    .contact{
        background-color: #eef2f8;
        padding-top: .28rem;
        padding-bottom: .28rem;
        &__title{
            font-size: .24rem;
            color: @blue;
            font-weight: bold;
            margin-bottom: 0.10rem;
        }
        &__item{
            color: #7a7e80;
            font-size: .2rem;
            font-weight: normal;
            margin-bottom: .1rem;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    .copyright{
        font-size: .18rem;
        color: #fff;
        // height: .8rem;
        // display: flex;
        // flex-direction: column;
        // justify-content: center;
        // align-items: flex-start;
        padding-top: .15rem;
        padding-bottom: .15rem;
        background-color: #004ea2;
        &__left{
        }
        &__right{
            color: #fff;
        }
        img{
            display: inline-block;
        }
    }
}
.page{
    padding-top: .9rem;
}
.page-index{
    .banner{
        width: 100vw;
        height: 6.8rem;
        position: relative;
        // padding: 0 .45rem;
        box-sizing: border-box;
        img{
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
        }
        .content{
            color: #fff;
            position: absolute;
            left: .45rem;
            max-width: 6.5rem;
            bottom: .6rem;
            z-index: 2;
            text-align: center;
            .banner-title{
                font-size: .36rem;
                font-weight: bold;
                margin-bottom: .2rem;
            }
            .banner-desc{
                font-size: .22rem;
                line-height: 1.8;
            }
        }
    }
    .product-list{
        margin-top: 1.3rem;
        margin-bottom: 1.3rem;
        display: flex;
        flex-wrap: wrap;
        border-radius: 4px;
        overflow: hidden;
        > li{
            width: 3.3rem;
            height: 3.3rem;
        }
        > li > a{
            display: inline-block;
            width: inherit;
            height: inherit;
            background-image: url('../../../../img/mobile/01-index02_04.jpg');
            background-size: cover;
            position: relative;
            .mask{
                background-color: rgba(0, 0, 0,.4);
                position: absolute;
                left: 0;
                top: 0;
                z-index: 1;
                width: 100%;
                height: 100%;
            }
            .content{
                position: absolute;
                z-index: 2;
                left: .38rem;
                top: 50%;
                transform: translateY(-50%);
                .product-title{
                    font-size: .3rem;
                    position: relative;
                    margin-bottom: .75rem;
                    font-size: .3rem;
                    color: #fff;
                    &::before{
                        content: '';
                        width: 0.2rem;
                        height: 0.04rem;
                        background-color: #fff;
                        position: absolute;
                        left: 0;
                        bottom: -.2rem;
                    }
                }
                .bottom{
                    .get-more{
                        position: relative;
                        .b-1px(#fff);
                        color: #fff;
                        font-size: 12px;
                        padding: 0.04rem .15rem;
                        //width: 1.06rem;
                        //height: .36rem;
                        //.all-center();
                    }
                }
            }
        }
    }
    .banner1{
        padding-bottom: 1.5rem;
        .img{
            width: 100%;
            height: 4rem;
        }
        .content{
            background-color: @blue;
            color: #fff;
            padding: 0.6rem .3rem;
            .title{
                margin-bottom: .3rem;
                font-size: .3rem;
                color: #fff;
                font-weight: bold;
            }
            .desc{
                font-size: .24rem;
                line-height: 1.6;
                color: #fff;
                padding-bottom: .34rem;
            }
            .btn{
                width: 2.1rem;
                height: 0.6rem;
                background-color: #fff100;
                box-shadow: 0rem 0.18rem 0.54rem 0rem
                    rgba(4, 61, 128, 0.2);
                border-radius: 0.06rem;
                font-size: .24rem;
                color: @blue;
            }
        }
    }
    .platform-info-wrapper{
        margin-bottom: 1.48rem;
        .platform-info{
            background-color: #ffffff;
            box-shadow: 0rem 0.06rem 0.24rem 0rem
                rgba(17, 17, 18, 0.16);
            border-radius: 0.06rem;
            padding: 0.64rem .44rem;
            .platform-desc{
                font-size: .24rem;
                width: 5.1rem;
                margin: 0 auto;
                margin-bottom: .5rem;
                > .item{
                    text-align: right
                }
                .plus-quote{
                    color: @blue;
                    font-size: .46rem;
                    font-weight: bold;
                    position: relative;
                    margin-right: .3rem;
                    &::after{
                        content: '+';
                        display: inline;
                        font-size: .2rem;
                        color: @blue;
                        position: absolute;
                        right: -.2rem;
                        top: 0;
                    }
                }

            }
        }
        .platform-info-swiper{
            margin-bottom: .5rem;
            font-size: .24rem;
            color: @light-black;
            width: 5.7rem;
            position: relative;
            padding: .3rem 0;
            .swiper-slide{
                &::before,&::after{
                    content: '“';
                    display: inline-block;
                    color: @light-border;
                    position: absolute;
                    font-size: 1.5rem;
                    text-transform: uppercase;
                }

                &::before{
                    transform: translateY(-.4rem);
                    left: 0;
                    top: -.24rem;
                }
                &::after{
                    transform: matrix( -1,0,0,-1,0,0) translateY(-.4rem);
                    right: 0;
                    bottom: -.24rem;
                }
                .desc{
                    line-height: 1.8;
                    max-width: 4.58rem;
                    margin: 0 auto;
                    margin-bottom: .2rem;
                }
                .speaker{
                    margin: 0 auto;
                    max-width: 4.58rem;
                    font-size: .2rem;
                    color: @gray;
                    position: relative;
                    text-align: right;
                    // &:before{
                    //     content: '';
                    //     display: inline-block;
                    //     background-color: @gray;
                    //     width: 0.3rem;
                    //     height: 1px;
                    //     position: absolute;
                    //     left: -.4rem;
                    //     top: 50%;
                    //     transform: translateY(-50%);
                    // }
                }
            }
        }
    }
}
.top-banner{
    .bg(02-system_02,jpg);
    position: relative;
    color: #fff;
    .all-center();
    flex-direction: column;
    padding: 0 .4rem;
    box-sizing: border-box;
    text-align: center;
    width: 100vw;
	height: 4rem;
    .mask{
        background-color: rgba(0, 0, 0,.3);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    &__title,&__desc{
        position: relative;
        z-index: 2;
    }
    &__title{
        font-size: .3rem;
        font-weight: bold;
    }
    &__desc{
        margin-top: .28rem;
        font-size: .22rem;
        line-height: 1.8;
    }
}
.lib-swiper-pr,.base-swiper-pr{
    position: relative;
    .base-pagination{
        bottom: 0.6rem;
        width: 6.6rem;
        .swiper-pagination-bullet{
            width: 0.13rem;
            height: 0.13rem;
            background-color: @light-border;
            opacity: 1;
            & + .swiper-pagination-bullet{
                margin-left: .22rem;
            }
            &-active{
                opacity: 1;
                background-color: #0172f5;
            }
        }

    }
}
.product-swiper{
    border-radius: 6px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 3px 32px 0px rgba(4, 73, 149, 0.36);
    .swiper-slide{
        .img{
            .img-wrap(100%,3.62rem);
        }
        .content{
            padding-top: .44rem;
            padding-bottom: .8rem;
            padding-left: .3rem;
            padding-right: .3rem;
            .title{
                font-size: .28rem;
                color: @black;
                position: relative;
                margin-bottom: .4rem;
                &::before{
                    content: '';
                    display: inline-block;
                    position: absolute;
                    width: 0.2rem;
                    height: 0.03rem;
                    background-color: @blue;
                    left: 0;
                    bottom: -0.24rem;
                }
            }
            .desc{
                color: @light-black;
                font-size: .24rem;
                line-height: 1.8;
            }
        }
    }
    &.library-move-swiper{
        .swiper-slide{
            .img{
                .img-wrap(100%,4.2rem);
	            img{
		            max-height: unset;
	            }
            }
        }
    }
}
.page-move{
    .stat{
        .bg(move_06,jpg);
    }
    .top-banner{
        .bg(move_02,jpg);
    }
}
.page-teacher{
    .stat{
        .bg(teacher_06,jpg);
    }
}
.page-donate{
    .stat{
        .bg(donate_02,jpg);
    }
}
.stat{
    padding: 0.68rem .3rem .6rem .3rem;
    box-sizing: border-box;
    height: auto;
    min-height: 4rem;
    width: 100vw;
    .bg(system-04,jpg);
    .stat-list{
        margin-bottom: .2rem;
        .flex{
            display: flex;
            flex-wrap: wrap;
            .data-desc{
                margin-top: .16rem;
                width: calc(50%);
            }
            .data-desc:nth-of-type(1),.data-desc:nth-of-type(2){
                margin-top: 0;
            }
            .data-desc:nth-child(even){
                padding-left: .1rem;
            }
            .data-desc:nth-child(odd){
                padding-right: .1rem;
            }
        }
        .top{
            margin-bottom: .16rem;

        }
        color: #fff;
        font-size: .2rem;
        .number,.unit-text{
            font-size: .46rem;
            margin-left: .06rem;
            margin-right: .1rem;
        }
    }
    &.v-center{
        .all-center();
        .stat-list{
            margin-bottom: 0;
        }
    }
}
.project-recommend-swiper{
    padding: 1px;
    padding-top: .2rem;
    padding-bottom: .2rem;
    .swiper-slide{
        color: #fff;
        font-size: .24rem;
        .quote-desc{
            padding: .5rem .78rem;
            border-left: 1px solid #cad3dc;
            border-right: 1px solid #cad3dc;
            // .bg(quote-border);
            // background-size: 100% 100%;
            // background-repeat: repeat-y;
            // background-position: left top right bottom;
            position: relative;
            > .icon{
                position: absolute;
            }
            > .icon-quote-l{
                left: 0.35rem;
                top: -.18rem;
            }
            > .icon-quote-r{
                right: 0.35rem;
                bottom: -.18rem;
            }
            .t-box,.b-box{
                position: absolute;
                z-index: 2;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                &:before,&:after{
                    content: '';
                    display: inline-block;
                    position: absolute;
                    width: 100%;
                    height: 1px;
                    background-color: #cad3dc;
                }
            }
            .t-box{
                top: 0;
                &:before{
                    width: .18rem;
                    left: 0;
                    top: 0;
                }
                &:after{
                    top: 0;
                    width: 5.58rem;
                    right: 0;
                }
            }
            .b-box{
                bottom: 0;
                &:before{
                    width: 5.58rem;
                    left: 0;
                    bottom: 0;
                }
                &:after{
                    width: .18rem;
                    right: 0;
                    bottom: 0;
                }
            }
            .desc{
                line-height: 1.6;
                margin-bottom: .2rem;
            }
            .speaker{
                font-size: .2rem;
                text-align: right;
                color: lighten(#fff, 40%);
            }
        }
    }
}
.title-under-line{
    padding-top: 1rem;
    padding-bottom: .7rem;
    > .title{
        font-size: .3rem;
        color: @black;
        text-align: center;
        position: relative;
        &::before{
            content: '';
            position: absolute;
            display: inline-block;
            width: 0.2rem;
            height: 0.03rem;
            background-color: #0172f5;
            bottom: -.2rem;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}
.video-wrapper{
    background-color: #f7f9fa;
    padding-bottom: 1.18rem;
    //.video{
    //    width: 6.6rem;
    //    height: 3.1rem;
    //    border-radius: 0.06rem;
    //    overflow: hidden;
    //    > video{
    //        width: 6.6rem;
    //        height: 3.1rem;
    //        border-radius: 0.06rem;
    //    }
    //    > iframe{
    //        width: 100%;
    //        height: auto;
    //    }
    //}

    .video {
        cursor: pointer;
        position: relative;
        box-shadow: 0 8px 24px 2px rgba(1, 24, 35, 0.2);
        border-radius: 6px;
        overflow: hidden;

        &:before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            background-color: rgba(51, 51, 51, 0.25);
        }

        .icon-video {
            position: absolute;
            left: 50%;
            top: 50%;
            margin-left: -.3rem;
            margin-top: -.3rem;
            z-index: 2;
            cursor: pointer;
        }

        img{
            transition: all 3s;
        }

        &:hover{
            img{
                transform: scale(1.1);
            }
        }

    }
}
.peroration{
    padding-top: 1rem;
    .title{
        font-size: .3rem;
        text-align: center;
        color: @black;
        margin-bottom: .55rem;
        &.not-m{
            margin: 0;
        }
    }
    .desc{
        font-size: .24rem;
        color: @light-black;
        line-height: 1.8;
        text-align: center;
        margin: .25rem 0;
    }
    .qr-code{
        display: flex; // 添加此行以启用flex布局
        flex-direction: column; // 垂直排列
        justify-content: center; // 水平居中
        align-items: center; // 垂直居中
        width: 100%; // 确保宽度为100%
        
        img {
            width: 50%; // 二维码图片占横向50%
        }
    }
    .btn-group{
        .btn{
            & + .btn{
                margin-left: .3rem;
            }
        }
    }
}
.category{
    background-color: #fff;
    // box-shadow: 0rem 1px 6px rgba(0, 0, 0,.3);
    &-list{
        // overflow: hidden;
        // display: flex;
        a{
            padding: .2rem .2rem;
            font-size: .24rem;
            display: inline-block;
            position: relative;
            &.active{
                .b-1px-b(@blue);
                &::after {
                    height: 0.05rem;
                    border-bottom-width: 0.05rem;
                }
            }
        }
        .swiper-slider{
             a{
                padding: .2rem .4rem;
                &.active{
                    .b-1px-b(@blue);
                }
            }
        }
    }
}
.wrapper{
    background-color: #fafafa;
    padding-top: .7rem;
    .main{
        .news-list{
            li{
                margin-bottom: .3rem;
                a{
                    display: flex;
                    padding: 0.25rem;
                    background-color: #ffffff;
                    box-shadow: 0rem 0.02rem 0.2rem 0rem
                        rgba(3, 73, 149, 0.12);
                    border-radius: 0.06rem;
                    .img{
                        flex-shrink: 0;
                        // flex: 0 0 2.1rem;
                        .img-wrap(2.1rem,1.38rem);
                        margin-right: .2rem;
                    }
                    .content{
                        flex-grow: 1;
                        .title{
                            font-size: .26rem;
                            color: #4d4d4d;
                            line-height: 1.6;
                            margin-bottom: .2rem;
                            height: .8rem;
                        }
                        .time{
                            color: #888f95;
                            font-size: .24rem;
                        }
                    }
                }
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }
}
.get-more-data-box{
    padding-top: .7rem;
    padding-bottom: 1.3rem;
    text-align: center;
    width: 100%;
    font-size: .26rem;
    > a{
        color: #999;
        display: inline-block;
    }
    .show-loading{
        display: none;
        &.active{
            animation: loading .3s infinite;
            display: inline-block;
        }
    }
}
.bread-nav{
    padding: .3rem 0 .34rem 0;
    display: flex;
    > li{
        font-size: .24rem;
        margin-right: .35rem;
        & + li{
            position: relative;
            &::before{
                content: '>';
                display: inline-block;
                position: absolute;
                left: -.24rem;
                top: 50%;
                transform: translateY(-50%);
                color: @light-black;
            }
        }
        &:last-child{
            margin-right: 0;
        }
        > a{
            color: @light-black;
        }
    }
}
.article-wrapper{
    .meta{
        position: relative;
        .b-1px-b(@border);
        margin-bottom: .38rem;
        .title{
            font-size: .32rem;
            color: @black;
            line-height: 1.6;
            margin-bottom: .14rem;
        }
        .time{
            font-size: .24rem;
            color: @gray;
            padding-bottom: .4rem;
        }
    }
    .article-content{
        color: @gray;
    }
}
.page-about{
    .article-content{
        p{
            font-size: .26rem;
            color: @light-black;
            line-height: 1.6;
        }
    }
}
.about-swiper-wrapper{
    padding-bottom: .4rem;
    .base-pagination{
        bottom: -.1rem;
    }
    .swiper-slide{
        a{
            display: inline-block;
            .img-wrap(100%,3.52rem);
        }
    }
}
.read-union{
    .img{
        max-width: 100%;
        overflow: hidden;
    }
    .content{
        margin-top: .4rem;
        color: @light-black;
        line-height: 1.6;
        font-size: .26rem;
    }
}
.page-help{
    .module-list{
        padding: .7rem 0 1.3rem 0;
        > li{
            display: flex;
            background-color: #ffffff;
            box-shadow: 0rem 0.02rem 0.36rem 0rem
                rgba(0, 0, 0, 0.2);
            border-radius: 0.06rem;
            margin-bottom: .38rem;
            padding: .48rem .44rem;
            .img{
                max-width: 2.2rem;
                overflow: hidden;
                .all-center();
                flex-shrink: 0;
            }
            .content{
                flex-grow: 1;
                margin-left: .8rem;
                .title{
                    font-size: .3rem;
                    color: #4d4d4d;
                    font-weight: bold;
                    margin-bottom: .2rem;
                }
                .sub-module-list{
                    > li{
                        > a{
                            margin-bottom: .12rem;
                            color: #4d4d4d;
                            font-size: .24rem;
                            &:hover{
                                color: @blue;
                            }
                            &.active{
                                color: @blue;
                            }
                        }
                        &::last-child{
                            a{
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
            &::last-child{
                margin-bottom: 0;
            }
        }
    }
}

.model-mask{
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(51, 51, 51, 0.25);
	z-index: 998;
	display: none;
}

.model{
	z-index: 999;
	display: none;
	position: fixed;
	left: 50%;
	top: 50%;
	width: 5rem;
	height: 75%;
	background-color: #fff;
    &.active{
        transform: translate(-50%, -50%);
    }
	.model-header{
		padding: .15rem;
		border-bottom: 1px solid #f5f5f5;
		h4{
            font-weight: bold;
			font-size: .28rem;
		}
	}
	.model-content{
        overflow-y: auto;
		height: 84%;
		flex-grow: 1;
		border-bottom: 1px solid #f5f5f5;
		padding: .15rem;
		.content{
			font-size: .24rem;
		}
	}
	.model-footer{
        min-height: 1rem;
		padding: .15rem;
	}
}

.count{
    white-space: nowrap;
    display: inline-block;
}


// 新调整的
.xh-banner1 {
    .img {
        > img {
            width: 100%;
        }
    }
}
