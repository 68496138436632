//所有项目的公共样式
@import "./var";
@import "../../../common-var";

.bg(@src,@imgSuffix: png,@pos: center,@size: cover){
  background: url('@{imgDir}@{src}.@{imgSuffix}') no-repeat @pos;
  background-size: @size;
}
.all-center(){
  display: flex;
  align-items: center;
  justify-content: center;
}
.text-center{
  text-align: center !important;
}

.text-right{
  text-align: right !important;
}

.over-hidden{
  overflow: hidden !important;
}

.text-overflow{
  .over-hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.visibility-hidden{
  visibility: hidden !important;
}

strong{
  font-weight: bold;
}

em{
  font-style: italic;
}

i,span{
  display: inline-block;
}

.row{
  margin-left: -.3rem;
  margin-right: -.3rem;
}

.fix-footer{
  position: fixed;
  left: 0;
  bottom: 0;
  height: 1rem;
  width: 100%;
  background-color: #fff;
  padding: .15rem .3rem;
  box-shadow: 0 -1px 6px rgba(4, 47, 59, 0.15);
  z-index: 3;
}

//带有左边框的标题
.title-default{
  font-size: .26rem;
  color: #fff;
  font-weight: 400;
  display: flex;
  align-items: center;
  h4{
    line-height: 1.2;
    padding-left: .09rem;
    border-left: .06rem solid #fff;
  }
}

.mask-weixin {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 6;
  background: rgba(0, 0, 0, 0.7) url('@{imgDir}share-guide-codonate.png') no-repeat right 0.5rem/contain;
  max-width: 720px;
  margin: 0 auto;
}

.border-bottom{
  border-bottom: 1px solid @border;
}

.text-limit(@lineCount){
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: @lineCount;
  word-break: break-all;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

.text-limit2{
  .text-limit(2);
}
.text-limit3{
  .text-limit(3);
}
.text-limit4{
  .text-limit(4);
}
.text-limit5{
  .text-limit(5);
}

//图片超出裁剪
.img-wrap(@width,@height: auto) {
  width: @width;
  height: @height;
  overflow: hidden;
  text-align: center;
  line-height: @height;
  font-size: 0;
}

//设置圆形图片
.avatar(@size) {
  border-radius: 50%;
  overflow: hidden;
  text-align: center;
  width: @size;
  height: @size;
  line-height: @size;
  font-size: 0;
  img {
    border-radius: 50%;
  }
}

.hidden{
  display: none !important;
}

.table-cell,.v-align{
  display: table-cell;
  vertical-align: middle;
}

.clear-fix{
  &:after{
    content: '';
    display: inline-block;
    clear: both;
    visibility: hidden;
    height:0;
    line-height:0;
    font-size:0;
  }
}
ul.clear-fix{
  &:after{
    display: list-item;
  }
}

//perfect-scrollbar
@ps_y_width: 10px;
.scrollbar{
  position: relative;
  overflow: hidden;
}
.perfect-scrollbar-reset.ps .ps__rail-y{
  width: @ps_y_width;
  overflow: hidden;
  .ps__thumb-y{
    right: 0;
    background-color: #fff;
  }
}
.ps__thumb-y{
  cursor: pointer;
  width: 10px;
  right: 0;
  background-color:  #dbdbdb;
  box-shadow: 0 0 5px #fff;
}
.ps__rail-y{
  opacity: 0;
  width: @ps_y_width;
  border-radius: 25px;
  &:hover{
    .ps__thumb-y{
      background-color: #dbdbdb;
      width: @ps_y_width;
    }
  }
}
.ps__rail-x{
  display: none !important;
}
.ps--active-x > .ps__rail-x, .ps--active-y > .ps__rail-y{
  background-color: #ededed;
}
//perfect-scrollbar 结束

.img-info(@width: 50px,@height: 80px,@padding-left: 20px){
  display: flex;
  align-items: center;
  .img{
    .img-wrap(@width,@height);
    flex-shrink: 0;
  }
  .content{
    &.v-align{
      display: inline-flex;
      justify-content: center;
      flex-direction: column;
    }
    padding-left: @padding-left;
    min-height: @height;
    height: @height;
    position: relative;
    display: inline-flex;
    flex-grow: 1;
    >.title{
      line-height: 1.5;
    }
  }
}

.cursor-pointer{
  cursor: pointer;
}

.clear{
  clear: both;
}

.btn-reset(@width,@height){
  width: @width;
  height: @height;
}

.pull-left{
  float: left !important;
}

.pull-right{
  float: right !important;
}

//禁止触摸事件
.no-scroll{
  touch-action: none;
}
.pt110{
  padding-top: 1.10rem;
}
.pt30{
  padding-top: .3rem !important;
}

.pb30{
  padding-bottom: .3rem !important;
}
.pb110{
  padding-bottom: 1.10rem;
}
.pb0{
  padding-bottom: 0 !important;
}
.mb40{
  margin-bottom: .4rem !important;;
}

.mb30{
  margin-bottom: .3rem !important;;
}

.mb20{
  margin-bottom: .2rem !important;;
}

.mb25{
  margin-bottom: .25rem !important;;
}

.mt30{
  margin-top: .3rem !important;;
}

.mt60{
  margin-top: .6rem !important;;
}

.mt10{
  margin-top: .1rem !important;
}

.mt0{
  margin-top: 0 !important;
}

.mb0{
  margin-bottom: 0 !important;
}

.ml10{
  margin-left: .1rem;
}
.ml20{
  margin-left: .1rem;
}
.ml30{
  margin-left: .1rem;
}

.setTopLine(@c: #C7C7C7) {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 1px;
  border-top: 1px solid @c;
  color: @c;
  transform-origin: 0 0;
  transform: scaleY(0.5);
}

.setBottomLine(@c: #C7C7C7) {
  content: " ";
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 1px;
  border-bottom: 1px solid @c;
  color: @c;
  transform-origin: 0 100%;
  transform: scaleY(0.5);
}

.setLeftLine(@c: #C7C7C7) {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  bottom: 0;
  border-left: 1px solid @c;
  color: @c;
  transform-origin: 0 0;
  transform: scaleX(0.5);
}

.setRightLine(@c: #C7C7C7) {
  content: " ";
  position: absolute;
  right: 0;
  top: 0;
  width: 1px;
  bottom: 0;
  border-right: 1px solid @c;
  color: @c;
  transform-origin: 100% 0;
  transform: scaleX(0.5);
}

.setLine(@c: #C7C7C7) {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  width: 200%;
  height: 1px;
  border: 1px solid @c;
  color: @c;
  height: 200%;
  transform-origin: left top;
  transform: scale(0.5);
}

.b-1px, .b-1px-t, .b-1px-b, .b-1px-tb, .b-1px-l, .b-1px-r {
  position: relative;
}

.b-1px(@c) {
  &:before {
    .setLine(@c);
  }
}

.b-1px-t {
  &:before {
    .setTopLine();
  }
}

.b-1px-b(@c) {
  &:after {
    .setBottomLine(@c);
  }
}

.b-1px-tb {
  &:before {
    .setTopLine();
  }
  &:after {
    .setBottomLine();
  }
}

.b-1px-l {
  &:before {
    .setLeftLine();
  }
}

.b-1px-r {
  &:after {
    .setRightLine();
  }
}