@import "./var";
@import "../../../common-var";

.iconGen(@icon,@width:10px,@height:10px){
  background: url("@{iconDir}/@{icon}.png") no-repeat center;
  width: @width;
  height: @height;
  background-origin:content-box;
  background-size: @width @height;
  display: inline-block;
}

.icon-user{
  .iconGen(icon-user,.28rem,.27rem);
}

.icon-checked{
  .iconGen(icon-checked,.44rem,.44rem);
}

.icon-notice-gray{
  .iconGen(icon-notice-gray,.24rem,.24rem);
}

.icon-ali-pay{
  .iconGen(icon-ali-pay,1.31rem,.48rem);
}

.icon-wechat-pay{
  .iconGen(icon-wechat-pay,1.59rem,.5rem);
}

.icon-close{
  .iconGen(icon-close,.4rem,.4rem);
}

.icon-to-top{
  .iconGen(icon-to-top,.4rem,.4rem);
}
.icon-top{
  .iconGen(top,.2rem,.12rem);
}
.icon-quote-l{
  .iconGen(icon-quote-l,.38rem,.36rem);
}
.icon-quote-r{
  .iconGen(icon-quote-r,.39rem,.36rem);
}
.icon-loading{
  .iconGen(icon-load-more-gray,.3rem,.3rem);
}