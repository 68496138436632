.container{
  padding-left: .3rem;
  padding-right: .3rem;
}

.btn{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: .12rem .3rem;
  font-size: .24rem;
  border-radius: 0.06rem;
  cursor: pointer;
  text-align: center;
  .btn-color(@bg-color,@border-color: @bg-color,@color: @bg-color){
    background-color: @bg-color;
    color: #fff;
    border: 1px solid @border-color;
    &.btn-border{
      color: @color;
      background-color: #fff;
      border: 1px solid @color;
      &:hover{
        background-color: @border-color;
        color: #fff;
      }
    }
  }



  &.btn-blue{
    .btn-color(@blue);
  }

  &.btn-gray{
    .btn-color(#c3c3c3);
  }

  &.btn-square{
    border-radius: 0 !important;
  }
  &.btn-yellow{
    .btn-color(#fff100);
    color: lighten(#004ea2, 10%);
  }
  &.btn-block{
    display: flex;
    width: 100%;
    height: .7rem;
  }

}

button,a{
  &.btn{
    &:after,&:before{
      content: '';
      flex: 1 0 auto;
    }
  }
}

.btn-group{
  display: flex;
  justify-content: center;
  align-items: center;
  & + .btn{
    margin-left: .45rem;
  }
}

//视频icon
.icon-video {
  width: .6rem;
  height: .6rem;
  border-radius: 50%;
  background-color: #fff;
  line-height: .6rem;
  text-align: center;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -3px;
    margin-top: -7px;
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 9px solid @blue;
  }

  &.icon-video-blue {
    &:after {
      border-left: 18px solid @blue;
    }
  }
}

.input-white{
  color: @light-black;
  font-size: .26rem;
  height: .6rem;
  border: 1px solid @border;
}

.text-blue{
  color: @blue !important;
}

.text-red{
  color: @red !important;
}

.title-wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1.5;
  padding: .2rem 0 .15rem;
  margin-bottom: .2rem;
  border-bottom: 1px solid @blue;
  .title{
    font-size: .32rem;
    font-weight: bold;
    color: @blue;
  }
  .more{
    font-size: .24rem;
    color: @blue;
  }
}

.swiper-pagination{
  &.swiper-pagination-circle{
    .swiper-pagination-bullet{
      width: .15rem;
      height: .15rem;
      border-radius: 50%;
      background-color: transparent;
      border: 1px solid @blue;
      opacity: 1;
      &.swiper-pagination-bullet-active{
        background-color: @blue;
      }
    }
  }
}

.bar{
  border-bottom: .1rem solid @border;
}

.no-data{
  font-size: .24rem;
  color: @light-black;
  text-align: center;
  padding: .1rem 0;
}


.mfp-content{
  text-align: center;
  video{
    max-width: 100%;
    max-height: 100%;
  }
  button.mfp-close{
    color: #fff;
    font-size: .36rem;
    width: .44rem;
    height: .44rem;
    line-height: .44rem;
    border: 1px solid #fff;
    border-radius: 50%;
    transition: all .3s;
    top: -70px;
  }
}

